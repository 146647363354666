import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import Img from 'gatsby-image'
import { Section, SectionTitle, SectionDescription, SectionHeader, SectionPageTitle, MainPanel, LeftPannel, RightPannel, MarkdownContent, BreadCrumb } from "../components/section"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ProductForm from "../sections/Product/ProductForm"
import ProductCardList from "../components/TabProductCardList"

import DefaultButton from "../components/button/DefaultButton"
import DefaultOutlineButton from "../components/button/DefaultOutlineButton"
import PhoneIcon from '../components/Icons/PhoneIcon'
import QouteIcon from '../components/Icons/QouteIcon'
import BreakpointUp from "../components/Media/BreakpointUp"
import BreakpointDown from "../components/Media/BreakpointDown"
import QuotePopup from '../components/QuotePopup'
import generateHTML from '../utils/generateHTML';
import Content, { HTMLContent } from '../components/Content';


const SectionCover = styled.div`  
  display: flex;
  flex-wrap:wrap;
  align-items: center;
  margin:0 -15px;    
  ${BreakpointUp.lg`                  
    padding-left: calc((100% - 1350px)/2);
  `}
`
const CoverFigure = styled.div`
  padding: 0 15px;
  width:100%;
  height: 100%;
  display:none;
  ${BreakpointUp.lg`   
    display:block;
    flex: 0 0 50%;
    max-width: 50%;
  `}
  .gatsby-image-wrapper {
    height: 100%;
    max-height: 500px;
  }
`
const CoverContent = styled.div`
  position:relative;
  padding:0 15px;
  width:100%;
  ${BreakpointUp.lg`          
    flex: 0 0 50%;
    max-width: 50%;
  `}
  & p{
    margin-bottom:30px;
  }
`

const ButtonToolbar = styled.div`
  margin: 0 -10px;
  & a{
    padding: 0 10px;
    ${BreakpointDown.sm`          
      display:block;
      + a{
        margin-top:20px;
      }
    `}
    & .btn{
      padding: 15px 40px;
      font-size: 20px;
      line-height:26px;
      ${BreakpointDown.sm`     
        display:block;
      `}
    }
  }
  & .quote-btn {
    width: 100%;
    & .btn{
      padding: 16px 40px;
      font-size: 20px;
    }
    &:hover {
      cursor: pointer;
    }
    @media(max-width: 580px) {
      .btn {
        width: calc(100% - 20px);
        margin: 15px 10px;
      }
    }
  }
`
class ProductRoofLanding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisibleQuote: false,
    }
    this.showQuote = this.showQuote.bind(this)
    this.onClosePopup = this.onClosePopup.bind(this)
  }
  showQuote() {
    this.setState({
      isVisibleQuote: true,
    })
    if (typeof window !== `undefined`) {
      document.body.classList.add('popup-open')
    }
  }
  onClosePopup() {
    this.setState({
      isVisibleQuote: false,
    })
    if (typeof window !== `undefined`) {
      document.body.classList.remove('popup-open')
    }
  }
  render() {
    const { isVisibleQuote } = this.state
    const  { data, location } = this.props
    const pageData = data.contentfulProductRoofLanding
    const PostContent = HTMLContent || Content;
    const productList = data.allContentfulProduct
    const productsData = []
    productList.edges.forEach(function(item){
      if (item.node.productCategory?.name === pageData.productCateogry.name && item.node.productRoof.roofName === pageData.roofName.roofName) {
        productsData.push(item)
      }
    })
    return (
      <Layout location={location}>
        <SEO title={pageData.metaTitle} description={pageData.metaDescription}/>
        <SectionHeader pt="140px" pb="90px" xpt="140px" xpb="60px" bgColor="linear-gradient(to right,  #f6f6f6 0%,#f6f6f6 75%,#eaeaea 75%,#eaeaea 100%)">
          <div className="container">
            <SectionCover>
              <CoverContent>
                <BreadCrumb><Link to='/'>Home</Link>/<Link to={pageData.productCateogry.link}>{pageData.productCateogry.name}</Link>/<span>{pageData.title}</span></BreadCrumb>   
                <SectionPageTitle mt="30px" mb="15px" textAlign="left">{pageData.title}</SectionPageTitle>
                <SectionDescription mb="50px" textAlign="left">
                  <PostContent
                    content={generateHTML(pageData.heroDescription.childMarkdownRemark.html)}
                  />
                </SectionDescription>
                <ButtonToolbar>
                  <a href='tel:8776005980'>
                    <DefaultButton icon={<PhoneIcon />} text="(877) 600-5980" />
                  </a>
                  <span className="quote-btn" onClick={this.showQuote} aria-label="Request A Quote"  role="button"  tabIndex="0"><DefaultOutlineButton icon={<QouteIcon />} text="Request A Quote" /></span>
                </ButtonToolbar>
              </CoverContent> 
              {pageData.image && (
                <CoverFigure>
                  <Img fluid={pageData.image.fluid} />
                </CoverFigure>
              )} 
            </SectionCover>
          </div>
        </SectionHeader>
        <QuotePopup
				  isVisible={isVisibleQuote}
					onClose={this.onClosePopup}
					location={location}
				/>
        <Section pt="70px" pb="70px" xpt="60px" xpb="60px">
          <div className="container">
            <SectionTitle maxWidth="550px">{pageData.productListSectionTitle}</SectionTitle>
            <SectionDescription mb="50px">{pageData.productListSectionDescription.productListSectionDescription}</SectionDescription>        
            <ProductCardList
              roof = "true"
              data={productsData}
              buttonTxt="Explore all Buildings"
              buttonLink="/products"
              showQuote={this.showQuote}
            />
          </div>
        </Section>
        <Section pt="90px" pb="90px" bg="#F6F6F6" mt="30px">
          <div className="container">
            <MainPanel>
              <LeftPannel>
                <MarkdownContent>
                  <PostContent
                    content={generateHTML(pageData.content.childMarkdownRemark.html)}
                  />
                </MarkdownContent>
              </LeftPannel>
              <RightPannel>
                <ProductForm location={location} />
              </RightPannel>
            </MainPanel>
          </div>
        </Section>
      </Layout>
    )
  }
}

export default ProductRoofLanding

export const pageQuery = graphql`
  query ProductRoofLandingQuery($id: String!) {
    contentfulProductRoofLanding(id: { eq: $id }) {
      metaTitle
      metaDescription
      title
      heroDescription {
        childMarkdownRemark {
          html
        }
      }
      roofName {
        roofName
      }
      productListSectionTitle
      productListSectionDescription {
        productListSectionDescription
      }
      image {
        fluid {
          src
          base64
          aspectRatio
          srcSet
          srcSetWebp
          srcWebp
        }
      }
      content {
        childMarkdownRemark {
          html
        }
      }
      productCateogry {
        name
        link
      }
    }
    allContentfulProduct(
      sort: {fields: totalPrice}
    ) {
      edges {
        node {
          id
          productName
          image {
            fluid {
              base64
              aspectRatio
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          width
          height
          length
          totalPrice
          depositPrice
          popularLabel
          priceDescription
          featuresImage {
            title
            file {
              url
            }
          }
          productRoof {
            roofName
          }
          popularLabel
          url
          productCategory {
            name
            link
          }

        }
      }
    }
  }
`